import React, { ReactNode } from "react";
import { Skeleton, Box, SxProps, Theme } from "@mui/material";

interface SkeletonWrapperProps {
  isLoading: boolean;
  children: ReactNode;
  sx?: SxProps<Theme>; // Optional sx prop for styling
  className?: string;
  variant?: "circular" | "rectangular" | "rounded" | "text";
}

const SkeletonWrapper: React.FC<SkeletonWrapperProps> = ({
  isLoading,
  children,
  sx,
  className,
  variant,
}) => (
  <>
    {isLoading ? (
      <Skeleton
        variant={variant ? variant : "rounded"}
        sx={{ ...sx }}
        className={className || ""}
      />
    ) : (
      children
    )}
  </>
);

export default SkeletonWrapper;
