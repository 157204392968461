// IMPORT REACT
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import MedicalServicesOutlinedIcon from "@mui/icons-material/MedicalServicesOutlined";
// IMPORT CSS
import "./navbardrawer.scss";

// IMPORT UI COMPONENTS
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Grid,
  Collapse,
  Tooltip,
  Button,
  Typography,
  Avatar,
  Menu,
  useMediaQuery,
  SwipeableDrawer,
  Divider,
  useTheme,
  Popover,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CssBaseline from "@mui/material/CssBaseline";
import { BootstrapTooltip } from "../../../components/Common/BootstrapTooltip";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import Zoom from "@mui/material/Zoom";
import HmisLogo from "../../../components/CustomButton/HmisLogo/HmisLogo";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AutoAwesomeMosaicOutlinedIcon from "@mui/icons-material/AutoAwesomeMosaicOutlined";
import { ExpandLess, ExpandMore, Height } from "@mui/icons-material";
import GroupsIcon from "@mui/icons-material/Groups";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpIcon from "../../../assets/images/helpIcon.png";
import BusinessIcon from "@mui/icons-material/Business";
import LogoutIcon from "@mui/icons-material/Logout";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import FrontDeskOutlineIcon from "../../../assets/images/menu-icons/reception-desk-outline.png";
import OPDOutlineIcon from "../../../assets/images/menu-icons/stethoscope-outline.png";
import MedicalInformationOutlinedIcon from "@mui/icons-material/MedicalInformationOutlined";
import PersonIcon from "@mui/icons-material/Person";
import { PreferencesContext } from "../../../PreferenceContext";
import { GetBranchList } from "../../../services/BranchService";
import { GetOrganizationList } from "../../../services/OrganizationService";
import LeaderboardRoundedIcon from "@mui/icons-material/LeaderboardRounded";
import { Context } from "../../../services/models/Context";
import { PAGE_SIZE } from "../../../services/common/const";
import { GetSaveUserOrgBranchSelection } from "../../../services/UserService";
import { AlertType } from "../../../services/models/AlertType";
import AddIcon from "@mui/icons-material/Add";
import zIndex from "@mui/material/styles/zIndex";

// STYLED COMPONENTS
// =================Navbar Drawar============================
const drawerWidth = 210;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: "0.15s",
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: "0.15s",
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// =================Navbar Drawar============================

//MENU SCHEMA
interface IMenu {
  master: boolean;
  frontDesk: boolean;
  opd: boolean;
  patient: boolean;
  doctor: boolean;
  accounts: boolean;
  team: boolean;
  crm: boolean;
  new: boolean;
}

// INITIAL DATA FOR MENU
const menu: IMenu = {
  master: false,
  opd: false,
  patient: false,
  doctor: false,
  accounts: false,
  team: false,
  crm: false,
  frontDesk: false,
  new: false,
};
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 23,
  height: 23,
  cursor: "pointer",
}));
const parameters: any = {
  pageIndex: 1,
  pageSize: PAGE_SIZE,
  // financialYearEndDate: "2022-07-01T00:00:00",
  // financialYearStartDate: "2022-07-13T00:00:00",
  sortColumn: "",
  sortDirection: "",
  totalCount: "",
  maxAllowedBranches: "",
  searchValue: "",
};

const TrialAccount = () => {
  let navigate = useNavigate();
  const { userContext, updateUserContext } =
    React.useContext(PreferencesContext);
  const [trialTime, setTrialTime] = React.useState(
    new Date(userContext?.planEndDate)?.getTime() - new Date().getTime()
  );

  // For trial account
  React.useEffect(() => {
    if (trialTime > 0) {
      setTimeout(() => setTrialTime(trialTime - 1000), 1000);
      return true;
    } else {
      if (userContext.subscriptionId > 0) {
        navigate("/");
        var context: Context = new Context();
        updateUserContext(context);
      }
      return false;
    }
  });
  return <></>;
};

export default function Nav(props: any) {
  //HOOKS AND CONTEXT
  const navigate = useNavigate();
  const theme = useTheme();
  const matches = useMediaQuery("(max-width:1024px)");
  const smallScreen = useMediaQuery("(max-width:767px)");
  const mobileScreen = useMediaQuery("(max-width:425px)");
  const { search, pathname } = useLocation();
  const { userContext, updateUserContext } =
    React.useContext(PreferencesContext);
  const [param, setParam] = React.useState(parameters);
  // BREAK POINTS
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  // STATES FOR DATA STORAGE  -- PLEASE USE PROPER TYPES FOR STATE
  const [openMenu, setOpenMenu] = React.useState<IMenu>(menu);
  const [branchTotalCount, setBranchTotalCount] = React.useState(1);
  const [branchColl, setBranchColl] = React.useState<any[]>([]);
  const [organizationTotalCount, setOrganizationTotalCount] = React.useState<
    any[]
  >([]);
  const [orgColl, setOrganizationColl] = React.useState<any[]>([]);
  const [openMasterNull, setOpenMasterNull] = React.useState(false);
  const [state, setState] = React.useState({ left: false });
  const [organizationLogo, setOrganizationLogo] = React.useState("");

  // STATES -- FOR ERROR HANDLING AND LOADING
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const handleClickDashboard = () => {
    navigate("/dashboard");
    setOpenMenu({
      ...openMenu,
      frontDesk: false,
      opd: false,
      doctor: false,
      patient: false,
      team: false,
      crm: false,
      accounts: false,
      new: false,
    });
  };

  const handleClickMaster = () => {
    navigate("/master");
    setOpenMenu({
      ...openMenu,
      frontDesk: false,
      opd: false,
      doctor: false,
      patient: false,
      team: false,
      crm: false,
      accounts: false,
      new: false,
    });
  };

  const handleClickConsumer = () => {
    navigate("/consumers");
    setOpenMenu({
      ...openMenu,
      frontDesk: false,
      opd: false,
      doctor: false,
      patient: false,
      team: false,
      crm: false,
      accounts: false,
      new: false,
    });
  };

  const handleClickReport = () => {
    navigate("/report");
  };

  type Anchor = "top" | "left" | "bottom" | "right";

  const toggleDrawer =
    (anchor: any, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      let page = event !== undefined ? event.target.innerText : null;
      setOpenMenu({ ...openMenu, new: true });

      switch (page) {
        case "Dashboard": {
          navigate("/dashboard");
          break;
        }
        case "Master": {
          navigate("/master");
          break;
        }
        case "Consumer": {
          navigate("/consumers");
          break;
        }
        case "Add Appointments": {
          navigate("/add-appointment");
          break;
        }
        case "Appointments": {
          navigate("/appointments");
          break;
        }
        case "OPD Clinical": {
          navigate("/opd-clinical");
          break;
        }
        case "Certificates": {
          navigate("/certificates");
          break;
        }
        case "Patients": {
          navigate("/patient-master");
          break;
        }

        case "Patient History": {
          navigate("/patient-history");
          break;
        }
        case "Doctors": {
          navigate("/doctors");
          break;
        }
        case "Referral Doctors": {
          navigate("/referral-doctors");
          break;
        }
        case "Visiting Doctors": {
          navigate("/visiting-doctors");
          break;
        }
        case "Outreach Visits": {
          navigate("/outreach-visits");
          break;
        }
        case "Doctor Schedule": {
          navigate("/doctor-schedule");
          break;
        }
        case "Billing": {
          navigate("/billing");
          break;
        }
        case "Staff": {
          navigate("/staff");
          break;
        }
        case "Users": {
          navigate("/users");
          break;
        }
        case "Lead": {
          navigate("/lead");
          break;
        }
        case "Distribute": {
          navigate("/distribute");
          break;
        }
        default: {
        }
      }
      setState({ ...state, [anchor]: open });
    };

  const handleDrawerBtn = () => {
    setOpenMenu({
      ...openMenu,
      frontDesk: false,
      opd: false,
      doctor: false,
      patient: false,
      team: false,
      crm: false,
      accounts: false,
      new: !openMenu.new,
    });
  };

  const handleClickMasterNull = () => {
    setOpenMasterNull(openMasterNull);
  };

  const handleClickFrontDesk = () => {
    setOpenMenu({
      ...openMenu,
      frontDesk: !openMenu.frontDesk,
      opd: false,
      doctor: false,
      patient: false,
      team: false,
      crm: false,
      accounts: false,
      new: true,
    });
  };

  const handleClickOPD = () => {
    setOpenMenu({
      ...openMenu,
      frontDesk: false,
      opd: !openMenu.opd,
      doctor: false,
      patient: false,
      team: false,
      crm: false,
      accounts: false,
      new: true,
    });
  };

  const handleClickPatient = () => {
    setOpenMenu({
      ...openMenu,
      frontDesk: false,
      opd: false,
      doctor: false,
      patient: !openMenu.patient,
      team: false,
      crm: false,
      accounts: false,
      new: true,
    });
  };

  const handleClickDoctor = () => {
    setOpenMenu({
      ...openMenu,
      frontDesk: false,
      opd: false,
      doctor: !openMenu.doctor,
      patient: false,
      team: false,
      crm: false,
      accounts: false,
      new: true,
    });
  };

  const handleClickAccounts = () => {
    setOpenMenu({
      ...openMenu,
      frontDesk: false,
      opd: false,
      doctor: false,
      patient: false,
      team: false,
      crm: false,
      accounts: !openMenu.accounts,
      new: true,
    });
  };

  const handleClickTeam = () => {
    setOpenMenu({
      ...openMenu,
      frontDesk: false,
      opd: false,
      doctor: false,
      patient: false,
      team: !openMenu.team,
      crm: false,
      accounts: false,
      new: true,
    });
  };

  const handleClickCrm = () => {
    setOpenMenu({
      ...openMenu,
      frontDesk: false,
      opd: false,
      doctor: false,
      patient: false,
      team: false,
      crm: !openMenu.crm,
      accounts: false,
      new: true,
    });
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [anchorEl4, setAnchorEl4] = React.useState(null);
  const spanRef = React.useRef(null);
  const open4 = Boolean(anchorEl4);

  const profieClick = (any) => {
    setAnchorEl4(spanRef.current);
  };

  const profileClose = (page: string) => {
    switch (page) {
      case "My Profile": {
        setAnchorEl4(null);
        navigate("/profile");
        break;
      }
      case "Sign out": {
        setAnchorEl4(null);
        var context: Context = new Context();
        updateUserContext(context);
        navigate("/");
        break;
      }
      default: {
        setAnchorEl4(null);
      }
    }
    setAnchorEl4(null);
  };

  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const hide = () => setAnchorEl2(null);
  const [anchorBranch, setAnchorBranch] = React.useState<null | HTMLElement>(
    null
  );

  const handleCloseBranchPopUp = () => {
    setAnchorBranch(null);
  };

  const dashboardClick = () => {
    if (lg) {
      setState({ left: false });
    }
    navigate("/dashboard");
    setOpenMenu({
      ...openMenu,
      frontDesk: false,
      opd: false,
      doctor: false,
      patient: false,
      team: false,
      crm: false,
      accounts: false,
      new: true,
    });
  };

  const consumerClick = () => {
    if (lg) {
      setState({ left: false });
    }
    navigate("/consumers");
    setOpenMenu({
      ...openMenu,
      frontDesk: false,
      opd: false,
      doctor: false,
      patient: false,
      team: false,
      crm: false,
      accounts: false,
      new: true,
    });
  };

  const masterClick = () => {
    if (lg) {
      setState({ left: false });
    }
    navigate("/master");
    setOpenMenu({
      ...openMenu,
      frontDesk: false,
      opd: false,
      doctor: false,
      patient: false,
      team: false,
      crm: false,
      accounts: false,
      new: true,
    });
  };

  const settingClick = () => {
    if (lg) {
      setState({ left: false });
      navigate("/settings");
      setOpenMenu({
        ...openMenu,
        frontDesk: false,
        opd: false,
        doctor: false,
        patient: false,
        team: false,
        crm: false,
        accounts: false,
        new: true,
      });
    }
  };
  const helpClick = () => {
    if (lg) {
      setState({ left: false });
      navigate("/help");
      setOpenMenu({
        ...openMenu,
        frontDesk: false,
        opd: false,
        doctor: false,
        patient: false,
        team: false,
        crm: false,
        accounts: false,
        new: true,
      });
    }
  };

  React.useEffect(() => {
    if (
      userContext.loginDetail.loginClick === true &&
      userContext.loginDetail.userRole === "clientadmin" &&
      orgColl.length > 1
    ) {
      setAnchorEl4(null);
    }
  });

  //Mobile  View Data
  const list = (anchor: Anchor) => (
    <List style={{ width: "33vh" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          marginTop: "7px",
          marginLeft: "14px",
          marginBottom: "7px",
        }}
      >
        <HmisLogo className="logo nav-logo" size={38} />
      </Box>
      <Divider />
      <span
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "100%",
          paddingTop: "15px",
        }}
      >
        <span>
          <ListItem
            key={"Dashboard"}
            disablePadding
            className={
              "/dashboard" === pathname
                ? "active-master-class"
                : "nav-ListItemButton"
            }
            sx={{ display: "block" }}
            onClick={dashboardClick}
          >
            <ListItemButton
              sx={{
                minHeight: 24,
                justifyContent: openMenu.new ? "initial" : "center",
                px: 1.3,
                "&:hover": {
                  backgroundColor: "#3b75ff",
                  color: "#ffffff",
                },
              }}
              onClick={dashboardClick}
            >
              <ListItemIcon
                className="nav-ListItemButton"
                sx={{
                  minWidth: 0,
                  mr: openMenu.new ? 3 : "auto",
                  justifyContent: "center",
                }}
                onClick={dashboardClick}
              >
                {openMenu.new ? (
                  <span className="menu-icon-span">
                    <HomeOutlinedIcon className="menu-icon" />
                  </span>
                ) : (
                  <BootstrapTooltip
                    TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <span style={{ fontSize: "12px" }}>Dashboard</span>
                      </React.Fragment>
                    }
                    placement="right"
                  >
                    <span className="menu-icon-span">
                      <HomeOutlinedIcon className="menu-icon" />
                    </span>
                  </BootstrapTooltip>
                )}
              </ListItemIcon>
              <ListItemText
                primary={"Dashboard"}
                onClick={dashboardClick}
                className="menu-item-text"
                sx={{ opacity: openMenu.new ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          {userContext.loginDetail.userRole === "superadmin" && (
            <ListItem
              key={"Consumer"}
              disablePadding
              className={
                "/consumers" === pathname
                  ? "active-master-class"
                  : "nav-ListItemButton"
              }
              sx={{ display: "block" }}
              onClick={consumerClick}
            >
              <ListItemButton
                sx={{
                  minHeight: 24,
                  justifyContent: openMenu.new ? "initial" : "center",
                  px: 1.3,
                  "&:hover": {
                    backgroundColor: "#3b75ff",
                    color: "#ffffff",
                  },
                }}
                onClick={consumerClick}
              >
                <ListItemIcon
                  className="nav-ListItemButton"
                  sx={{
                    minWidth: 0,
                    mr: openMenu.new ? 3 : "auto",
                    justifyContent: "center",
                  }}
                  onClick={consumerClick}
                >
                  {openMenu.new ? (
                    <span className="menu-icon-span">
                      <PersonIcon className="menu-icon" />
                    </span>
                  ) : (
                    <BootstrapTooltip
                      TransitionComponent={Zoom}
                      title={
                        <React.Fragment>
                          <span style={{ fontSize: "12px" }}>Consumers</span>
                        </React.Fragment>
                      }
                      placement="right"
                    >
                      <span className="menu-icon-span">
                        <PersonIcon className="menu-icon" />
                      </span>
                    </BootstrapTooltip>
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={"Consumer"}
                  onClick={consumerClick}
                  sx={{ opacity: openMenu.new ? 1 : 0 }}
                  className="menu-item-text"
                />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem
            key={"FrontDesk"}
            disablePadding
            className="nav-ListItemButton"
            sx={{ display: "block" }}
          >
            <ListItemButton
              onClick={
                openMenu.new ? handleClickFrontDesk : handleClickMasterNull
              }
              sx={{
                minHeight: 24,
                justifyContent: openMenu.new ? "initial" : "center",
                px: 1.3,
                borderRadius: "12px",
                "&:hover": {
                  backgroundColor: "#e9eefa",
                  color: "#3b75ff",
                },
              }}
            >
              <ListItemIcon
                className="nav-ListItemButton"
                onClick={handleClickFrontDesk}
                sx={{
                  minWidth: 0,
                  mr: openMenu.new ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {openMenu.new ? (
                  <span className="menu-icon-span">
                    {/* <img
                      src={FrontDeskOutlineIcon}
                      alt="menu icon"
                      height={25}
                      width={25}
                    /> */}
                    <MedicalInformationOutlinedIcon className="menu-icon" />
                  </span>
                ) : (
                  <BootstrapTooltip
                    TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <span style={{ fontSize: "12px" }}>Front Desk</span>
                      </React.Fragment>
                    }
                    placement="right"
                  >
                    <span className="menu-icon-span">
                      {/* <img
                        src={FrontDeskOutlineIcon}
                        alt="menu icon"
                        height={25}
                        width={25}
                      />
                       */}
                      <MedicalInformationOutlinedIcon className="menu-icon" />
                    </span>
                  </BootstrapTooltip>
                )}
              </ListItemIcon>

              <ListItemText
                primary="Front Desk"
                sx={{ opacity: openMenu.new ? 1 : 0 }}
                className={
                  "/add-mr" === pathname ||
                  "/add-appointment" === pathname ||
                  "/appointments" === pathname
                    ? "active-master-class-icon menu-item-text"
                    : "menu-item-text"
                }
              />
              {openMenu.frontDesk ? (
                <ExpandLess
                  sx={{
                    borderRadius: "12px",
                    "&:hover": {
                      backgroundColor: "#e9eefa",
                      color: "#3b75ff",
                    },
                  }}
                  className={
                    "/add-mr" === pathname ||
                    "/add-appointment" === pathname ||
                    "/appointments" === pathname
                      ? "active-master-class-icon menu-item-icon-more"
                      : "menu-item-icon-more"
                  }
                />
              ) : (
                <ExpandMore
                  sx={{
                    borderRadius: "12px",
                    "&:hover": {
                      backgroundColor: "#e9eefa",
                      color: "#3b75ff",
                    },
                  }}
                  className={
                    "/add-mr" === pathname ||
                    "/add-appointment" === pathname ||
                    "/appointments" === pathname
                      ? "active-master-class-icon menu-item-icon-more"
                      : "menu-item-icon-more"
                  }
                />
              )}
            </ListItemButton>
            <Collapse in={openMenu.frontDesk} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className="">
                <ListItemButton
                  className={
                    "/add-appointment" === pathname
                      ? "active-master-class sub-menu"
                      : "sub-menu"
                  }
                  sx={{
                    pl: 4,
                    borderRadius: "12px",
                    "&:hover": {
                      backgroundColor: "#e9eefa",
                      color: "#3b75ff",
                    },
                  }}
                  onClick={toggleDrawer("left", false)}
                >
                  <ListItemText primary="Add Appointments" />
                </ListItemButton>
                <ListItemButton
                  className={
                    "/appointments" === pathname
                      ? "active-master-class sub-menu"
                      : "sub-menu"
                  }
                  sx={{
                    pl: 4,
                    borderRadius: "12px",
                    "&:hover": {
                      backgroundColor: "#e9eefa",
                      color: "#3b75ff",
                    },
                  }}
                  onClick={toggleDrawer("left", false)}
                >
                  <ListItemText primary="Appointments" />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>
          {userContext.loginDetail.userRole !== "user" && (
            <ListItem
              key={"OPD"}
              disablePadding
              className="nav-ListItemButton"
              sx={{ display: "block" }}
            >
              <ListItemButton
                onClick={openMenu.new ? handleClickOPD : handleClickMasterNull}
                sx={{
                  minHeight: 24,
                  justifyContent: openMenu.new ? "initial" : "center",
                  px: 1.3,
                  borderRadius: "12px",
                  "&:hover": {
                    backgroundColor: "#e9eefa",
                    color: "#3b75ff",
                  },
                }}
              >
                <ListItemIcon
                  className="nav-ListItemButton"
                  onClick={handleClickOPD}
                  sx={{
                    minWidth: 0,
                    mr: openMenu.new ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {openMenu.new ? (
                    <span className="menu-icon-span">
                      {/* <img
                    
                      src={OPDOutlineIcon}
                      alt="menu icon"
                      height={25}
                      width={25}
                    /> */}
                      <MedicalServicesOutlinedIcon className="menu-icon" />
                    </span>
                  ) : (
                    <BootstrapTooltip
                      TransitionComponent={Zoom}
                      title={
                        <React.Fragment>
                          <span style={{ fontSize: "12px" }}>OPD</span>
                        </React.Fragment>
                      }
                      placement="right"
                    >
                      <span className="menu-icon-span">
                        <MedicalServicesOutlinedIcon className="menu-icon" />
                        {/* <img
                        src={OPDOutlineIcon}
                        alt="menu icon"
                        height={25}
                        width={25}
                      /> */}
                      </span>
                    </BootstrapTooltip>
                  )}
                </ListItemIcon>

                <ListItemText
                  primary="OPD"
                  sx={{ opacity: openMenu.new ? 1 : 0 }}
                  className={
                    "/opd-clinical" === pathname || "/certificates" === pathname
                      ? "active-master-class-icon menu-item-text"
                      : "menu-item-text"
                  }
                />
                {openMenu.opd ? (
                  <ExpandLess
                    sx={{
                      borderRadius: "12px",
                      "&:hover": {
                        backgroundColor: "#e9eefa",
                        color: "#3b75ff",
                      },
                    }}
                    className={
                      "/opd-clinical" === pathname ||
                      "/certificates" === pathname
                        ? "active-master-class-icon menu-item-icon-more"
                        : "menu-item-icon-more"
                    }
                  />
                ) : (
                  <ExpandMore
                    className={
                      "/opd-clinical" === pathname ||
                      "/certificates" === pathname
                        ? "active-master-class-icon menu-item-icon-more"
                        : "menu-item-icon-more"
                    }
                  />
                )}
              </ListItemButton>
              <Collapse in={openMenu.opd} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className="">
                  <ListItemButton
                    className={
                      "/opd-clinical" === pathname
                        ? "active-master-class sub-menu"
                        : "sub-menu"
                    }
                    sx={{
                      pl: 4,
                      borderRadius: "12px",
                      "&:hover": {
                        backgroundColor: "#e9eefa",
                        color: "#3b75ff",
                      },
                    }}
                    onClick={toggleDrawer("left", false)}
                  >
                    <ListItemText primary="OPD Clinical" />
                  </ListItemButton>
                  {/* <ListItemButton
                  className={
                    "/certificates" === pathname
                      ? "active-master-class sub-menu"
                      : "sub-menu"
                  }
                  sx={{
                    pl: 4,
                    borderRadius: "12px",
                    "&:hover": {
                      backgroundColor: "#e9eefa",
                      color: "#3b75ff",

                    },
                  }}
                  onClick={toggleDrawer("left", false)}
                >
                  <ListItemText primary="Certificates" />
                </ListItemButton> */}
                </List>
              </Collapse>
            </ListItem>
          )}

          <ListItem
            key={"Patient"}
            disablePadding
            className="nav-ListItemButton"
            sx={{ display: "block" }}
          >
            <ListItemButton
              onClick={
                openMenu.new ? handleClickPatient : handleClickMasterNull
              }
              sx={{
                minHeight: 24,
                justifyContent: openMenu.new ? "initial" : "center",
                px: 1.3,
                borderRadius: "12px",
                "&:hover": {
                  backgroundColor: "#e9eefa",
                  color: "#3b75ff",
                },
              }}
            >
              <ListItemIcon
                className="nav-ListItemButton"
                onClick={handleClickPatient}
                sx={{
                  minWidth: 0,
                  mr: openMenu.new ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {openMenu.new ? (
                  <span className="menu-icon-span">
                    <AssignmentIndOutlinedIcon
                      className={
                        "/patient-master" === pathname ||
                        "/patient-history" === pathname
                          ? "active-master-class-icon menu-icon"
                          : "menu-icon"
                      }
                    />
                  </span>
                ) : (
                  <BootstrapTooltip
                    TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <span style={{ fontSize: "12px" }}>Patient</span>
                      </React.Fragment>
                    }
                    placement="right"
                  >
                    <span className="menu-icon-span">
                      <AssignmentIndOutlinedIcon
                        className={
                          "/patient-master" === pathname ||
                          "/patient-history" === pathname
                            ? "active-master-class-icon menu-icon"
                            : "menu-icon"
                        }
                      />
                    </span>
                  </BootstrapTooltip>
                )}
              </ListItemIcon>

              <ListItemText
                primary="Patient"
                sx={{ opacity: openMenu.new ? 1 : 0 }}
                className={
                  "/patient-master" === pathname ||
                  "/patient-history" === pathname
                    ? "active-master-class-icon menu-item-text"
                    : "menu-item-text"
                }
              />
              {openMenu.patient ? (
                <ExpandLess
                  sx={{
                    borderRadius: "12px",
                    "&:hover": {
                      backgroundColor: "#e9eefa",
                      color: "#3b75ff",
                    },
                  }}
                  className={
                    "/patient-master" === pathname ||
                    "/patient-history" === pathname
                      ? "active-master-class-icon menu-item-icon-more"
                      : "menu-item-icon-more"
                  }
                />
              ) : (
                <ExpandMore
                  className={
                    "/patient-master" === pathname ||
                    "/patient-history" === pathname
                      ? "active-master-class-icon menu-item-icon-more"
                      : "menu-item-icon-more"
                  }
                />
              )}
            </ListItemButton>
            <Collapse in={openMenu.patient} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className="">
                <ListItemButton
                  className={
                    "/patient-master" === pathname
                      ? "active-master-class sub-menu"
                      : "sub-menu"
                  }
                  sx={{
                    pl: 4,
                    borderRadius: "12px",
                    "&:hover": {
                      backgroundColor: "#e9eefa",
                      color: "#3b75ff",
                    },
                  }}
                  onClick={toggleDrawer("left", false)}
                >
                  <ListItemText primary="Patients" />
                </ListItemButton>
                {/* <ListItemButton
                  className={
                    "/patient-history" === pathname
                      ? "active-master-class sub-menu"
                      : "sub-menu"
                  }
                  sx={{
                    pl: 4,
                    borderRadius: "12px",
                    "&:hover": {
                      backgroundColor: "#e9eefa",
                      color: "#3b75ff",

                    },
                  }}
                  onClick={toggleDrawer("left", false)}
                >
                  <ListItemText primary="Patient History" />
                </ListItemButton> */}
              </List>
            </Collapse>
          </ListItem>
          {userContext.loginDetail.userRole !== "user" && (
            <ListItem
              key={"Team"}
              disablePadding
              className="nav-ListItemButton"
              sx={{ display: "block" }}
            >
              <ListItemButton
                onClick={openMenu.new ? handleClickTeam : handleClickMasterNull}
                sx={{
                  minHeight: 24,
                  justifyContent: openMenu.new ? "initial" : "center",
                  px: 1.3,
                  borderRadius: "12px",
                  "&:hover": {
                    backgroundColor: "#e9eefa",
                    color: "#3b75ff",
                  },
                }}
              >
                <ListItemIcon
                  className="nav-ListItemButton"
                  onClick={handleClickTeam}
                  sx={{
                    minWidth: 0,
                    mr: openMenu.new ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {openMenu.new ? (
                    <span className="menu-icon-span">
                      <GroupsIcon
                        className={
                          "/staff" === pathname || "/users" === pathname
                            ? "active-master-class-icon menu-icon"
                            : "menu-icon"
                        }
                      />
                    </span>
                  ) : (
                    <BootstrapTooltip
                      TransitionComponent={Zoom}
                      title={
                        <React.Fragment>
                          <span style={{ fontSize: "12px" }}>Team</span>
                        </React.Fragment>
                      }
                      placement="right"
                    >
                      <span className="menu-icon-span">
                        <GroupsIcon
                          className={
                            "/staff" === pathname || "/users" === pathname
                              ? "active-master-class-icon menu-icon"
                              : "menu-icon"
                          }
                        />
                      </span>
                    </BootstrapTooltip>
                  )}
                </ListItemIcon>

                <ListItemText
                  primary="Team"
                  sx={{ opacity: openMenu.new ? 1 : 0 }}
                  className={
                    "/staff" === pathname || "/users" === pathname
                      ? "active-master-class-icon menu-item-text"
                      : "menu-item-text"
                  }
                />
                {openMenu.team ? (
                  <ExpandLess
                    sx={{
                      borderRadius: "12px",
                      "&:hover": {
                        backgroundColor: "#e9eefa",
                        color: "#3b75ff",
                      },
                    }}
                    className={
                      "/staff" === pathname || "/users" === pathname
                        ? "active-master-class-icon menu-item-icon-more"
                        : "menu-item-icon-more"
                    }
                  />
                ) : (
                  <ExpandMore
                    className={
                      "/staff" === pathname || "/users" === pathname
                        ? "active-master-class-icon menu-item-icon-more"
                        : "menu-item-icon-more"
                    }
                  />
                )}
              </ListItemButton>
              <Collapse in={openMenu.team} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className="">
                  <ListItemButton
                    className={
                      "/staff" === pathname
                        ? "active-master-class sub-menu"
                        : "sub-menu"
                    }
                    sx={{
                      pl: 4,
                      borderRadius: "12px",
                      "&:hover": {
                        backgroundColor: "#e9eefa",
                        color: "#3b75ff",
                      },
                    }}
                    onClick={toggleDrawer("left", false)}
                  >
                    <ListItemText primary="Staff" />
                  </ListItemButton>
                  <ListItemButton
                    className={
                      "/users" === pathname
                        ? "active-master-class sub-menu"
                        : "sub-menu"
                    }
                    sx={{
                      pl: 4,
                      borderRadius: "12px",
                      "&:hover": {
                        backgroundColor: "#e9eefa",
                        color: "#3b75ff",
                      },
                    }}
                    onClick={toggleDrawer("left", false)}
                  >
                    <ListItemText primary="Users" />
                  </ListItemButton>
                </List>
              </Collapse>
            </ListItem>
          )}

          {userContext.loginDetail.userRole === "superadmin" && (
            <ListItem
              key={"CRM"}
              disablePadding
              className="nav-ListItemButton"
              sx={{ display: "block" }}
            >
              <ListItemButton
                onClick={openMenu.new ? handleClickCrm : handleClickMasterNull}
                sx={{
                  minHeight: 24,
                  justifyContent: openMenu.new ? "initial" : "center",
                  px: 1.3,
                  borderRadius: "12px",
                  "&:hover": {
                    backgroundColor: "#e9eefa",
                    color: "#3b75ff",
                  },
                }}
              >
                <ListItemIcon
                  className="nav-ListItemButton"
                  onClick={handleClickCrm}
                  sx={{
                    minWidth: 0,
                    mr: openMenu.new ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {openMenu.new ? (
                    <span className="menu-icon-span">
                      <LeaderboardRoundedIcon
                        className={
                          "/lead" === pathname || "/distribute" === pathname
                            ? "active-master-class-icon menu-icon"
                            : "menu-icon"
                        }
                      />
                    </span>
                  ) : (
                    <BootstrapTooltip
                      TransitionComponent={Zoom}
                      title={
                        <React.Fragment>
                          <span style={{ fontSize: "12px" }}>CRM</span>
                        </React.Fragment>
                      }
                      placement="right"
                    >
                      <span className="menu-icon-span">
                        <LeaderboardRoundedIcon
                          className={
                            "/lead" === pathname || "/distribute" === pathname
                              ? "active-master-class-icon menu-icon"
                              : "menu-icon"
                          }
                        />
                      </span>
                    </BootstrapTooltip>
                  )}
                </ListItemIcon>

                <ListItemText
                  primary="CRM"
                  sx={{ opacity: openMenu.new ? 1 : 0 }}
                  className={
                    "/lead" === pathname || "/distribute" === pathname
                      ? "active-master-class-icon menu-item-text"
                      : "menu-item-text"
                  }
                />
                {openMenu.crm ? (
                  <ExpandLess
                    sx={{
                      borderRadius: "12px",
                      "&:hover": {
                        backgroundColor: "#e9eefa",
                        color: "#3b75ff",
                      },
                    }}
                    className={
                      "/lead" === pathname || "/distribute" === pathname
                        ? "active-master-class-icon menu-item-icon-more"
                        : "menu-item-icon-more"
                    }
                  />
                ) : (
                  <ExpandMore
                    className={
                      "/lead" === pathname || "/distribute" === pathname
                        ? "active-master-class-icon menu-item-icon-more"
                        : "menu-item-icon-more"
                    }
                  />
                )}
              </ListItemButton>
              <Collapse in={openMenu.crm} timeout="auto" unmountOnExit>
                <List component="div" disablePadding className="">
                  <ListItemButton
                    className={
                      "/lead" === pathname
                        ? "active-master-class sub-menu"
                        : "sub-menu"
                    }
                    sx={{
                      pl: 4,
                      borderRadius: "12px",
                      "&:hover": {
                        backgroundColor: "#e9eefa",
                        color: "#3b75ff",
                      },
                    }}
                    onClick={toggleDrawer("left", false)}
                  >
                    <ListItemText primary="Lead" />
                  </ListItemButton>
                  <ListItemButton
                    className={
                      "/distribute" === pathname
                        ? "active-master-class sub-menu"
                        : "sub-menu"
                    }
                    sx={{
                      pl: 4,
                      borderRadius: "12px",
                      "&:hover": {
                        backgroundColor: "#e9eefa",
                        color: "#3b75ff",
                      },
                    }}
                    onClick={toggleDrawer("left", false)}
                  >
                    <ListItemText primary="Distribute" />
                  </ListItemButton>
                </List>
              </Collapse>
            </ListItem>
          )}
          <ListItem
            key={"Accounts"}
            disablePadding
            className="nav-ListItemButton"
            sx={{ display: "block" }}
          >
            <ListItemButton
              onClick={
                openMenu.new ? handleClickAccounts : handleClickMasterNull
              }
              sx={{
                minHeight: 24,
                justifyContent: openMenu.new ? "initial" : "center",
                px: 1.3,
                borderRadius: "12px",
                "&:hover": {
                  backgroundColor: "#e9eefa",
                  color: "#3b75ff",
                },
              }}
            >
              <ListItemIcon
                className="nav-ListItemButton"
                sx={{
                  minWidth: 0,
                  mr: openMenu.new ? 3 : "auto",
                  justifyContent: "center",
                }}
                onClick={handleClickAccounts}
              >
                {openMenu.new ? (
                  <span className="menu-icon-span">
                    <AccountBalanceIcon
                      className={
                        "/billing" === pathname
                          ? "active-master-class-icon menu-icon"
                          : "menu-icon"
                      }
                    />
                  </span>
                ) : (
                  <BootstrapTooltip
                    TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <span style={{ fontSize: "12px" }}>Accounts</span>
                      </React.Fragment>
                    }
                    placement="right"
                  >
                    <span className="menu-icon-span">
                      <AccountBalanceIcon
                        className={
                          "/billing" === pathname
                            ? "active-master-class-icon menu-icon"
                            : "menu-icon"
                        }
                      />
                    </span>
                  </BootstrapTooltip>
                )}
              </ListItemIcon>

              <ListItemText
                primary="Accounts"
                sx={{ opacity: openMenu.new ? 1 : 0 }}
                className={
                  "/billing" === pathname
                    ? "active-master-class-icon menu-item-text"
                    : "menu-item-text"
                }
              />
              {openMenu.accounts ? (
                <ExpandLess
                  sx={{
                    borderRadius: "12px",
                    "&:hover": {
                      backgroundColor: "#e9eefa",
                      color: "#3b75ff",
                    },
                  }}
                  className={
                    "/billing" === pathname
                      ? "active-master-class-icon menu-item-icon-more"
                      : "menu-item-icon-more"
                  }
                />
              ) : (
                <ExpandMore
                  className={
                    "/billing" === pathname
                      ? "active-master-class-icon menu-item-icon-more"
                      : "menu-item-icon-more"
                  }
                />
              )}
            </ListItemButton>
            <Collapse in={openMenu.accounts} timeout="auto" unmountOnExit>
              <List component="div" disablePadding className="">
                <ListItemButton
                  className={
                    "/billing" === pathname
                      ? "active-master-class sub-menu"
                      : "sub-menu"
                  }
                  sx={{
                    pl: 4,
                    borderRadius: "12px",
                    "&:hover": {
                      backgroundColor: "#e9eefa",
                      color: "#3b75ff",
                    },
                  }}
                  onClick={toggleDrawer("left", false)}
                >
                  <ListItemText primary="Billing" />
                </ListItemButton>
              </List>
            </Collapse>
          </ListItem>

          {userContext.loginDetail.userRole !== "user" && (
            <ListItem
              key={"Master"}
              disablePadding
              className={
                "/master" === pathname
                  ? "active-master-class"
                  : "nav-ListItemButton"
              }
              sx={{ display: "block" }}
              onClick={masterClick}
            >
              <ListItemButton
                sx={{
                  minHeight: 24,
                  justifyContent: openMenu.new ? "initial" : "center",
                  px: 1.3,
                  "&:hover": {
                    backgroundColor: "#3b75ff",
                    color: "#ffffff",
                  },
                }}
              >
                <ListItemIcon
                  className="nav-ListItemButton"
                  sx={{
                    minWidth: 0,
                    mr: openMenu.new ? 3 : "auto",
                    justifyContent: "center",
                  }}
                  onClick={masterClick}
                >
                  {openMenu.new ? (
                    <span className="menu-icon-span">
                      <AutoAwesomeMosaicOutlinedIcon className="menu-icon" />
                    </span>
                  ) : (
                    <BootstrapTooltip
                      TransitionComponent={Zoom}
                      title={
                        <React.Fragment>
                          <span style={{ fontSize: "12px" }}>Master</span>
                        </React.Fragment>
                      }
                      placement="right"
                    >
                      <span className="menu-icon-span">
                        <AutoAwesomeMosaicOutlinedIcon className="menu-icon" />
                      </span>
                    </BootstrapTooltip>
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={"Master"}
                  onClick={masterClick}
                  sx={{ opacity: openMenu.new ? 1 : 0 }}
                  className="menu-item-text"
                />
              </ListItemButton>
            </ListItem>
          )}

          {userContext.loginDetail.userRole !== "user" && (
            <ListItem
              key={"Settings"}
              disablePadding
              className={
                "/settings" === pathname
                  ? "active-master-class"
                  : "nav-ListItemButton"
              }
              sx={{ display: "block" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 24,
                  justifyContent: openMenu.new ? "initial" : "center",
                  px: 1.3,
                  "&:hover": {
                    backgroundColor: "#3b75ff",
                    color: "#ffffff",
                  },
                }}
              >
                <ListItemIcon
                  className="nav-ListItemButton"
                  sx={{
                    minWidth: 0,
                    mr: openMenu.new ? 3 : "auto",
                    justifyContent: "center",
                  }}
                  onClick={settingClick}
                >
                  {openMenu.new ? (
                    <span className="menu-icon-span">
                      <SettingsIcon className="menu-icon" />
                    </span>
                  ) : (
                    <BootstrapTooltip
                      TransitionComponent={Zoom}
                      title={
                        <React.Fragment>
                          <span style={{ fontSize: "12px" }}>Settings</span>
                        </React.Fragment>
                      }
                      placement="right"
                    >
                      <span className="menu-icon-span">
                        <SettingsIcon className="menu-icon" />
                      </span>
                    </BootstrapTooltip>
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={"Settings"}
                  onClick={settingClick}
                  sx={{ opacity: openMenu.new ? 1 : 0 }}
                  className="menu-item-text"
                />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem
            key={"Help"}
            disablePadding
            className={
              "/help " === pathname
                ? "active-master-class"
                : "nav-ListItemButton"
            }
            sx={{ display: "block" }}
          >
            <ListItemButton
              sx={{
                minHeight: 24,
                justifyContent: openMenu.new ? "initial" : "center",
                px: 1.3,
                "&:hover": {
                  backgroundColor: "#3b75ff",
                  color: "#ffffff",
                },
              }}
            >
              <ListItemIcon
                className="nav-ListItemButton"
                sx={{
                  minWidth: 0,
                  mr: openMenu.new ? 3 : "auto",
                  justifyContent: "center",
                }}
                onClick={helpClick}
              >
                {openMenu.new ? (
                  <span className="menu-icon-span">
                    <img
                      src={HelpIcon}
                      alt="menu icon"
                      height={25}
                      width={25}
                    />
                  </span>
                ) : (
                  <BootstrapTooltip
                    TransitionComponent={Zoom}
                    title={
                      <React.Fragment>
                        <span style={{ fontSize: "12px" }}>Help</span>
                      </React.Fragment>
                    }
                    placement="right"
                  >
                    <span className="menu-icon-span">
                      <img
                        src={HelpIcon}
                        alt="menu icon"
                        height={25}
                        width={25}
                      />
                    </span>
                  </BootstrapTooltip>
                )}
              </ListItemIcon>
              <ListItemText
                primary={"Help"}
                onClick={helpClick}
                sx={{ opacity: openMenu.new ? 1 : 0 }}
                className="menu-item-text"
              />
            </ListItemButton>
          </ListItem>
        </span>
      </span>
    </List>
  );

  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
      hash = string?.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 9)) & 0xff;
      color += `00${value?.toString(12)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  }
  function stringAvatar(name: string | null | undefined) {
    if (!name) {
      return {
        sx: {
          bgcolor: "#ccc", // Default background color when name is null or empty
          width: 35,
          height: 35,
          fontSize: 15,
        },
        children: "?", // Default character when name is null or empty
      };
    }

    const nameParts = name.split(" ");
    const firstInitial = nameParts[0]?.[0]?.toUpperCase() || "";
    const secondInitial =
      nameParts.length > 1 ? nameParts[1]?.[0]?.toUpperCase() || "" : "";

    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 35,
        height: 35,
        fontSize: 15,
      },
      children: `${firstInitial}${secondInitial || ""}`,
    };
  }

  const [hideProfileDiv, setHideProfileDiv] = React.useState(false);
  const toggleHide = () => {
    setHideProfileDiv(!hideProfileDiv);
    updateUserContext({
      ...userContext,
      loginDetail: {
        ...userContext.loginDetail,
        loginClick: false,
      },
    });
  };
  const LoginEntryPopUp = () => {
    setHideProfileDiv(true);
    setAnchorEl4(null);
    updateUserContext({
      ...userContext,
      loginDetail: {
        ...userContext.loginDetail,
        loginClick: false,
      },
    });
  };

  const handleBranchOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorBranch(event.currentTarget);
  };

  const getBranchList = async () => {
    try {
      let params = new URLSearchParams();
      params.append("consumerId", userContext.consumerId?.toString());
      params.append("orgId", userContext.organizationId?.toString());
      params.append("pageIndex", param.pageIndex?.toString());
      params.append("pageSize", param.pageSize?.toString());
      params.append("sortColumn", param.sortColumn);
      params.append("sortDirection", param.sortDirection);
      params.append("searchValue", param.searchValue);
      const result: any = await GetBranchList(params);
      if (result?.data?.data?.branchColl) {
        const resultData: any = result?.data?.data?.branchColl || [];
        let dataCount = result?.data?.data?.totalCount || 1;
        setBranchTotalCount(dataCount);
        setBranchColl(resultData);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("err:", e);
    }
  };

  const getOrganizationList = async () => {
    try {
      let params = new URLSearchParams();
      params.append("consumerId", userContext.consumerId?.toString());
      params.append("pageIndex", param.pageIndex?.toString());
      params.append("pageSize", param.pageSize?.toString());
      params.append("sortColumn", param.sortColumn);
      params.append("sortDirection", param.sortDirection);
      params.append("searchValue", param.searchValue);
      const result: any = await GetOrganizationList(params);
      if (result?.data?.data?.organizationColl) {
        const resultData = result?.data?.data?.organizationColl || [];
        let dataCount = result?.data?.data?.totalCount || 0;
        setOrganizationTotalCount(dataCount);
        setOrganizationColl(resultData);

        let selectedLogo = resultData.find(
          (e) => e.organizationId === userContext.organizationId
        );
        setOrganizationLogo(selectedLogo.logoServerImage);
      }
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("err:", e);
    }
  };

  async function handleListItemBranchClick(item: any) {
    updateUserContext({
      ...userContext,
      // financialYear: getCurrentFinancialYear(),
      // financialYearStartDate: getCurrentFinancialYearStartDate(),
      // financialYearEndDate: getCurrentFinancialYearEndDate(),
      branchId: item.branchId,
      branchName: item.branchName,
      isAlert: true,
      alertMessage: "Branch changed to " + item.branchName + "",
      alertType: AlertType.Success,
    });
    let params = new URLSearchParams();
    params.append("orgId", item.orgId.toString());
    params.append("userId", userContext.loginDetail.userId.toString());
    params.append("consumerId", userContext.consumerId.toString());
    params.append("branchId", userContext.branchId.toString());
    try {
      const result: any = await GetSaveUserOrgBranchSelection(params);
      let editDetails = result.data.data;
      let updatedValue = {
        branchId: editDetails.branchId,
        branchName: editDetails.branchName,
      };
      // setBranchResultDataByOrganization(updatedValue);
      setAnchorEl4(null);
      // setLoading(false);
      if (pathname === "/dashboard") {
        navigate(0);
      } else {
        navigate("/dashboard");
      }
    } catch (e: any) {
      // setLoading(false);
      // updateUserContext({
      //   ...userContext,
      //   isAlert: true,
      //   alertMessage:
      //     typeof e === "string" ? (e as string) : (e?.message as string),
      //   alertType: AlertType.Error,
      // });
    }
  }
  const handleListItemOrganizationClick = async (item: any) => {
    // setLoading(true);
    let params = new URLSearchParams();
    params.append("orgId", item.organizationId.toString());
    params.append("userId", userContext.loginDetail.userId.toString());
    params.append("consumerId", userContext.consumerId.toString());
    try {
      const result: any = await GetSaveUserOrgBranchSelection(params);
      let editDetails = result.data.data;
      let updatedValue = {
        organizationId: editDetails.orgId,
        organizationName: editDetails.orgName,
        branchId: editDetails.branchId,
        branchName: editDetails.branchName,
      };
      // setBranchResultDataByOrganization(updatedValue);
      updateUserContext({
        ...userContext,
        organizationId: updatedValue.organizationId,
        organizationName: updatedValue.organizationName,
        branchId: updatedValue.branchId,
        branchName: updatedValue.branchName,
        isAlert: true,
        alertMessage: "Sign in to " + updatedValue.organizationName,
        alertType: AlertType.Success,
      });
      setAnchorEl4(null);
      getBranchList();
      // setLoading(false);
      if (pathname === "/dashboard") {
        navigate(0);
      } else {
        navigate("/dashboard");
      }
    } catch (e: any) {
      // setLoading(false);
      // updateUserContext({
      //   ...userContext,
      //   isAlert: true,
      //   alertMessage:
      //     typeof e === "string" ? (e as string) : (e?.message as string),
      //   alertType: AlertType.Error,
      // });
    }
  };

  function stringAvatarOrganization(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: 35,
        height: 35,
        fontSize: 15,
      },
      children: `${name?.split(" ")[0][0]?.toUpperCase()}`,
    };
  }

  React.useEffect(() => {
    getBranchList();
  }, [userContext.organizationId]);

  React.useEffect(() => {
    getOrganizationList();
  }, [userContext.consumerId]);

  return (
    <Box>
      {lg ? (
        <>
          <AppBar
            style={{
              paddingBottom: "0px !important",
              borderBottom: "1px solid rgba(0, 0, 0, 0.04)",
              zIndex: "1000",
            }}
            position="fixed"
            open={openMenu.new}
          >
            <CssBaseline />
            <Box>
              <Toolbar
                className="new"
                sx={{
                  backgroundColor: "#ffffff",
                  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px !important",
                }}
              >
                <IconButton
                  color="primary"
                  aria-label="open drawer"
                  onClick={toggleDrawer("left", !state.left)}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{ marginRight: "50px", marginLeft: "10px" }}
                  ></div>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    {organizationLogo ? (
                      <Grid
                        //onClick={profieClick}
                        aria-describedby={id}
                        onClick={handleClick}
                        className="logo_grid"
                      >
                        <Grid
                          style={{
                            paddingRight: "8px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={organizationLogo}
                            alt="Organization Logo"
                            className="logo_image"
                          />
                        </Grid>
                        <IconButton
                          size="small"
                          //onClick={profieClick}
                          aria-describedby={id}
                          onClick={handleClick}
                          sx={{
                            p: 0,
                            padding: "5px 0px",
                            paddingLeft: "0px !important",
                          }}
                        >
                          <Avatar
                            {...stringAvatar(
                              userContext.loginDetail.firstName +
                                " " +
                                userContext.loginDetail.lastName
                            )}
                          />
                        </IconButton>
                      </Grid>
                    ) : (
                      <IconButton
                        size="small"
                        //onClick={profieClick}
                        aria-describedby={id}
                        onClick={handleClick}
                        sx={{ p: 0, paddingLeft: "0px !important" }}
                      >
                        <Avatar
                          {...stringAvatar(
                            userContext.loginDetail.firstName +
                              " " +
                              userContext.loginDetail.lastName
                          )}
                        />
                      </IconButton>
                    )}
                  </Box>
                </Box>
              </Toolbar>
            </Box>
          </AppBar>

          <SwipeableDrawer
            anchor="left"
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
            onOpen={toggleDrawer("left", true)}
          >
            <Box>{list("left")}</Box>
          </SwipeableDrawer>
          <DrawerHeader id="drwerheader" />
          <Box sx={{ padding: "16px" }}>{props.children}</Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            padding: "0px !important",
          }}
        >
          <AppBar
            style={{
              paddingBottom: "0px !important",
              borderBottom: "1px solid rgba(0, 0, 0, 0.04)",
            }}
            position="fixed"
            open={openMenu.new}
          >
            <CssBaseline />
            <Box>
              <Toolbar
                sx={{
                  backgroundColor: "#f1f5f8",
                  boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px !important",
                }}
              >
                <IconButton
                  className="icon-color"
                  aria-label="open drawer"
                  onClick={handleDrawerBtn}
                  edge="start"
                >
                  {openMenu.new ? <MenuOpenOutlinedIcon /> : <MenuIcon />}
                </IconButton>
                <Box
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      marginRight: "50px",
                      marginLeft: "10px",
                      marginTop: "8px",
                    }}
                  >
                    <HmisLogo className="logo nav-logo" size={38} />
                  </div>

                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    {!smallScreen && (
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          className="secondary-color"
                          sx={{
                            textAlign: "right",
                            margin: "0px",
                            padding: "0px",
                          }}
                        >
                          <span style={{ fontSize: "14PX", fontWeight: "600" }}>
                            {userContext?.organizationName}
                          </span>
                        </Typography>
                        <Typography
                          className="secondary-color"
                          sx={{
                            textAlign: "right",
                            margin: 0,
                            padding: 0,
                            lineHeight: "1.2",
                          }}
                        >
                          <span style={{ fontSize: "11px" }}>
                            {userContext?.branchName}
                          </span>
                        </Typography>
                      </Box>
                    )}
                    {userContext.loginDetail.userRole !== "user" && (
                      <Box sx={{ ml: 2 }}>
                        <Tooltip title="Settings">
                          <IconButton
                            className="icon-color"
                            aria-label=""
                            onClick={() => navigate("/settings")}
                          >
                            <SettingsIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    )}
                    <Box sx={{ ml: 1, mr: 1 }}>
                      <Tooltip title="Help">
                        <IconButton
                          className="icon-color"
                          aria-label=""
                          onClick={() => navigate("/help")}
                        >
                          <img
                            src={HelpIcon}
                            alt="menu icon"
                            height={25}
                            width={25}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Box>
                      {organizationLogo ? (
                        <Grid
                          //onClick={profieClick}
                          aria-describedby={id}
                          onClick={handleClick}
                          className="logo_grid"
                        >
                          <Grid
                            style={{
                              paddingRight: "8px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={organizationLogo}
                              alt="Organization Logo"
                              className="logo_image"
                            />
                          </Grid>
                          <IconButton
                            size="small"
                            //onClick={profieClick}
                            aria-describedby={id}
                            onClick={handleClick}
                            sx={{
                              p: 0,
                              padding: "5px 0px",
                              paddingLeft: "0px !important",
                            }}
                          >
                            <Avatar
                              {...stringAvatar(
                                userContext.loginDetail.firstName +
                                  " " +
                                  userContext.loginDetail.lastName
                              )}
                            />
                          </IconButton>
                        </Grid>
                      ) : (
                        <IconButton
                          size="small"
                          //onClick={profieClick}
                          aria-describedby={id}
                          onClick={handleClick}
                          sx={{ p: 0, paddingLeft: "0px !important" }}
                        >
                          <Avatar
                            {...stringAvatar(
                              userContext.loginDetail.firstName +
                                " " +
                                userContext.loginDetail.lastName
                            )}
                          />
                        </IconButton>
                      )}
                    </Box>

                    {/* 
                      logout box
                    */}
                    {/* <Box component="span" ref={spanRef}>
                      {userContext.loginDetail.loginClick === true ||
                        (userContext.loginDetail.userRole === "clientadmin" &&
                          orgColl.length < 2) ? (
                        <Menu
                          className="main-profie-container"
                          anchorEl={anchorEl4}
                          open={open4}
                          sx={{ maxWidth: "329px !important", border:"solid  4px red" }}
                          onClose={() => profileClose("")}
                          // MenuListProps={{
                          //   "aria-labelledby": "basic-button3",
                          // }}
                        >
                          <Grid>
                            <>
                              <Grid className="profile-user-detail-component">
                                <Grid className="profile-component-grid">
                                  <Grid width={"100%"} paddingX={"8px"}>
                                    <Grid className="organization-container">
                                      <Grid
                                        color={"#0b0b45 !important"}
                                        sx={{ paddingTop: "2px" }}
                                      >
                                        <BusinessIcon />
                                      </Grid>
                                      <Grid marginTop={0.5} marginLeft={1.3}>
                                        <h5 className="profilename">
                                          {userContext.organizationName} {" ("}
                                          {userContext.branchName}
                                          {")"}
                                        </h5>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid className="avatar-upload">
                                    <Grid className="avatar-edit"></Grid>
                                    <Grid className="avatar-preview">
                                      <Typography
                                        className="avtar"
                                        {...stringAvatar(
                                          userContext.loginDetail.fullName
                                        )}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>
                                <Grid className="profile-name-grid">
                                  <h4 className="text-size-medium">
                                    {userContext.loginDetail.firstName +
                                      " " +
                                      userContext.loginDetail.lastName}
                                  </h4>
                                  <Grid style={{ marginTop: "-5px" }}>
                                    <Typography className="text-size-small">
                                      {userContext.loginDetail.email}
                                    </Typography>
                                  </Grid>
                                  <Grid className="grid-myprofile">
                                    <Button
                                      className="one-myprofile"
                                      color="primary"
                                      variant="outlined"
                                      size="medium"
                                      onClick={() => profileClose("My Profile")}
                                    >
                                      My Profile
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                              {branchTotalCount > 1  &&
                              branchColl.length > 1 &&
                                userContext.loginDetail.userRole !== "user"
                                //&& userContext.subscriptionId > 0 
                                ? (
                                  <Grid className="branch-selection-component">
                                    <PopupState
                                      variant="popover"
                                      popupId="demo-popup-popover"
                                    >
                                      {(popupState) => (
                                        <div>
                                          <BootstrapTooltip
                                            title={userContext.branchName}
                                          >
                                            <Button
                                              className="branch-selection-btn"
                                              {...bindTrigger(popupState)}
                                              variant="outlined"
                                              startIcon={<BusinessIcon />}
                                            >
                                              <Typography className="branch-selection">
                                                {userContext.branchName}
                                              </Typography>
                                            </Button>
                                          </BootstrapTooltip>
                                          <Popover
                                            {...bindPopover(popupState)}
                                            anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "center",
                                            }}
                                            transformOrigin={{
                                              vertical: "top",
                                              horizontal: "center",
                                            }}
                                            className="nav-popover-branch"
                                          >
                                            <Box
                                              className="branch-container"
                                              sx={{
                                                "& > :not(style)": {
                                                  maxwidth: "20px",
                                                },
                                              }}
                                            >
                                              <List>
                                                {branchColl.map(
                                                  (item: any, index) => {
                                                    return (
                                                      <ListItem
                                                        disablePadding
                                                        button
                                                        onClick={() => {
                                                          popupState.close();
                                                          handleListItemBranchClick(
                                                            item
                                                          );
                                                        }}
                                                        key={index}
                                                      >
                                                        <ListItemText
                                                          primary={item.branchName}
                                                        />
                                                      </ListItem>
                                                    );
                                                  }
                                                )}
                                              </List>
                                            </Box>
                                          </Popover>
                                        </div>
                                      )}
                                    </PopupState>
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                              {userContext.loginDetail.userRole === "superadmin" ||
                                userContext.loginDetail.userRole === "clientadmin" ? (
                                <Grid className="organization-component">
                                  <List sx={{ padding: "0px !important" }}>
                                    {orgColl.map((item: any, index) => {
                                      return (
                                        <Grid
                                          item
                                          className="organization-list-data"
                                          key={index}
                                        >
                                          {userContext.organizationId === item.organizationId ? (
                                            <></>
                                          ) : (
                                            <ListItem
                                              disablePadding
                                              button
                                              onClick={() =>
                                                handleListItemOrganizationClick(item)
                                              }
                                              key={index}
                                            >
                                              <span className="organization-list-icon">
                                                <Avatar
                                                  {...stringAvatarOrganization(item.organizationName)}
                                                  style={{ paddingTop: "4px" }}
                                                />
                                              </span>
                                              <ListItemText
                                                primary={
                                                  <h4 style={{ fontSize: "14px" }}>
                                                    {item.organizationName}
                                                  </h4>
                                                }
                                              />
                                            </ListItem>
                                          )}
                                        </Grid>
                                      );
                                    })}
                                  </List>
                                </Grid>
                              ) : null}
                              <Grid className="sign-out-grid">
                                <Grid>
                                  <Button
                                    className="sign-out-btn-myprofile"
                                    color="primary"
                                    variant="contained"
                                    size="medium"
                                    onClick={() => profileClose("Sign out")}
                                    startIcon={<LogoutIcon />}
                                  >
                                    Sign out
                                  </Button>
                                </Grid>
                              </Grid>
                            </>
                          </Grid>
                        </Menu>
                      ) : (
                        <></>
                      )}
                    </Box> */}
                    {/* 
                      logout box
                    */}
                  </Box>
                </Box>
              </Toolbar>
            </Box>
          </AppBar>
          <Drawer
            variant="permanent"
            open={openMenu.new}
            className="drawer-main"
            style={{ transitionDuration: "0.15s" }}
            onMouseEnter={(e) => {
              setOpenMenu({ ...openMenu, new: true });
            }}
            onMouseLeave={(e) => {
              setOpenMenu({
                master: false,
                frontDesk: false,
                opd: false,
                patient: false,
                doctor: false,
                accounts: false,
                team: false,
                crm: false,
                new: false,
              });
            }}
          >
            <DrawerHeader />
            <List
              className="drawer-container"
              sx={{
                boxShadow: "rgba(20, 46, 110, 0.1) 0px 1px 8px !important",
                height: "100vh",
                border: "none",
              }}
            >
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <ListItem
                  key={"Dashboard"}
                  disablePadding
                  className={
                    "/dashboard" === pathname
                      ? "active-master-class"
                      : "nav-ListItemButton"
                  }
                  sx={{ display: "block" }}
                  onClick={handleClickDashboard}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 24,
                      justifyContent: openMenu.new ? "initial" : "center",
                      px: 0.5,
                      borderRadius: "12px",
                      "&:hover": {
                        backgroundColor: "#e9eefa",
                        color: "#3b75ff",
                      },
                    }}
                  >
                    <ListItemIcon
                      className="nav-ListItemButton"
                      sx={{
                        minWidth: 0,
                        mr: openMenu.new ? 3 : "auto",
                        justifyContent: "center",
                      }}
                      onClick={handleClickDashboard}
                    >
                      {openMenu.new ? (
                        <span className="menu-icon-span">
                          <HomeOutlinedIcon className="menu-icon" />
                        </span>
                      ) : (
                        <BootstrapTooltip
                          TransitionComponent={Zoom}
                          title={
                            <React.Fragment>
                              <span style={{ fontSize: "12px" }}>
                                Dashboard
                              </span>
                            </React.Fragment>
                          }
                          placement="right"
                        >
                          <span className="menu-icon-span">
                            <HomeOutlinedIcon className="menu-icon" />
                          </span>
                        </BootstrapTooltip>
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={"Dashboard"}
                      onClick={handleClickDashboard}
                      className="menu-item-text"
                      sx={{ opacity: openMenu.new ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
                {userContext.loginDetail.userRole === "superadmin" && (
                  <ListItem
                    key={"Consumer"}
                    disablePadding
                    className={
                      "/consumers" === pathname
                        ? "active-master-class"
                        : "nav-ListItemButton"
                    }
                    sx={{ display: "block" }}
                    onClick={handleClickConsumer}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 24,
                        justifyContent: openMenu.new ? "initial" : "center",
                        px: 0.5,
                        borderRadius: "12px",
                        "&:hover": {
                          backgroundColor: "#e9eefa",
                          color: "#3b75ff",
                        },
                      }}
                    >
                      <ListItemIcon
                        className="nav-ListItemButton"
                        sx={{
                          minWidth: 0,
                          mr: openMenu.new ? 3 : "auto",
                          justifyContent: "center",
                        }}
                        onClick={handleClickConsumer}
                      >
                        {openMenu.new ? (
                          <span className="menu-icon-span">
                            <PersonIcon className="menu-icon" />
                          </span>
                        ) : (
                          <BootstrapTooltip
                            TransitionComponent={Zoom}
                            title={
                              <React.Fragment>
                                <span style={{ fontSize: "12px" }}>
                                  Consumers
                                </span>
                              </React.Fragment>
                            }
                            placement="right"
                          >
                            <span className="menu-icon-span">
                              <PersonIcon className="menu-icon" />
                            </span>
                          </BootstrapTooltip>
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={"Consumer"}
                        onClick={handleClickConsumer}
                        sx={{ opacity: openMenu.new ? 1 : 0 }}
                        className="menu-item-text"
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                <ListItem
                  key={"FrontDesk"}
                  disablePadding
                  className="nav-ListItemButton"
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    onClick={
                      openMenu.new
                        ? handleClickFrontDesk
                        : handleClickMasterNull
                    }
                    sx={{
                      minHeight: 24,
                      justifyContent: openMenu.new ? "initial" : "center",
                      px: 0.5,
                      borderRadius: "12px",
                      "&:hover": {
                        backgroundColor: "#e9eefa",
                        color: "#3b75ff",
                      },
                    }}
                  >
                    <ListItemIcon
                      className="nav-ListItemButton"
                      onClick={handleClickFrontDesk}
                      sx={{
                        minWidth: 0,
                        mr: openMenu.new ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {openMenu.new ? (
                        <span className="menu-icon-span">
                          {/* <img
                            src={FrontDeskOutlineIcon}
                            alt="menu icon"
                            height={25}
                            width={25}
                          /> */}
                          <MedicalInformationOutlinedIcon className="menu-icon" />
                        </span>
                      ) : (
                        <BootstrapTooltip
                          TransitionComponent={Zoom}
                          title={
                            <React.Fragment>
                              <span style={{ fontSize: "12px" }}>
                                Front Desk
                              </span>
                            </React.Fragment>
                          }
                          placement="right"
                        >
                          <span className="menu-icon-span">
                            {/* <img
                              src={FrontDeskOutlineIcon}
                              alt="menu icon"
                              height={25}
                              width={25}
                            /> */}
                            <MedicalInformationOutlinedIcon className="menu-icon" />
                          </span>
                        </BootstrapTooltip>
                      )}
                    </ListItemIcon>

                    <ListItemText
                      primary="Front Desk"
                      sx={{ opacity: openMenu.new ? 1 : 0 }}
                      className={
                        "/add-mr" === pathname ||
                        "/add-appointment" === pathname ||
                        "/appointments" === pathname
                          ? "active-master-class-icon menu-item-text"
                          : "menu-item-text"
                      }
                    />
                    {openMenu.frontDesk ? (
                      <ExpandLess
                        sx={{
                          borderRadius: "12px",
                          "&:hover": {
                            backgroundColor: "#e9eefa",
                            color: "#3b75ff",
                          },
                        }}
                        className={
                          "/add-mr" === pathname ||
                          "/add-appointment" === pathname ||
                          "/appointments" === pathname
                            ? "active-master-class-icon menu-item-icon-more"
                            : "menu-item-icon-more"
                        }
                      />
                    ) : (
                      <ExpandMore
                        sx={{
                          borderRadius: "12px",
                          "&:hover": {
                            backgroundColor: "#e9eefa",
                            color: "#3b75ff",
                          },
                        }}
                        className={
                          "/add-mr" === pathname ||
                          "/add-appointment" === pathname ||
                          "/appointments" === pathname
                            ? "active-master-class-icon menu-item-icon-more"
                            : "menu-item-icon-more"
                        }
                      />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={openMenu.frontDesk}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding className="">
                      <ListItemButton
                        className={
                          "/add-appointment" === pathname
                            ? "active-master-class sub-menu"
                            : "sub-menu"
                        }
                        sx={{
                          pl: 4,
                          borderRadius: "12px",
                          "&:hover": {
                            backgroundColor: "#e9eefa",
                            color: "#3b75ff",
                          },
                        }}
                        onClick={toggleDrawer("right", false)}
                      >
                        <ListItemText primary="Add Appointments" />
                      </ListItemButton>
                      <ListItemButton
                        className={
                          "/appointments" === pathname
                            ? "active-master-class sub-menu"
                            : "sub-menu"
                        }
                        sx={{
                          pl: 4,
                          borderRadius: "12px",
                          "&:hover": {
                            backgroundColor: "#e9eefa",
                            color: "#3b75ff",
                          },
                        }}
                        onClick={toggleDrawer("right", false)}
                      >
                        <ListItemText primary="Appointments" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </ListItem>
                {userContext.loginDetail.userRole !== "user" && (
                  <ListItem
                    key={"OPD"}
                    disablePadding
                    className="nav-ListItemButton"
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      onClick={
                        openMenu.new ? handleClickOPD : handleClickMasterNull
                      }
                      sx={{
                        minHeight: 24,
                        justifyContent: openMenu.new ? "initial" : "center",
                        px: 0.5,
                        borderRadius: "12px",
                        "&:hover": {
                          backgroundColor: "#e9eefa",
                          color: "#3b75ff",
                        },
                      }}
                    >
                      <ListItemIcon
                        className="nav-ListItemButton"
                        onClick={handleClickOPD}
                        sx={{
                          minWidth: 0,
                          mr: openMenu.new ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {openMenu.new ? (
                          <span className="menu-icon-span">
                            <MedicalServicesOutlinedIcon className="menu-icon" />
                          </span>
                        ) : (
                          <BootstrapTooltip
                            TransitionComponent={Zoom}
                            title={
                              <React.Fragment>
                                <span style={{ fontSize: "12px" }}>OPD</span>
                              </React.Fragment>
                            }
                            placement="right"
                          >
                            <span className="menu-icon-span">
                              <MedicalServicesOutlinedIcon className="menu-icon" />
                            </span>
                          </BootstrapTooltip>
                        )}
                      </ListItemIcon>

                      <ListItemText
                        primary="OPD"
                        sx={{ opacity: openMenu.new ? 1 : 0 }}
                        className={
                          "/opd-clinical" === pathname ||
                          "/certificates" === pathname
                            ? "active-master-class-icon menu-item-text"
                            : "menu-item-text"
                        }
                      />
                      {openMenu.opd ? (
                        <ExpandLess
                          sx={{
                            borderRadius: "12px",
                            "&:hover": {
                              backgroundColor: "#e9eefa",
                              color: "#3b75ff",
                            },
                          }}
                          className={
                            "/opd-clinical" === pathname ||
                            "/certificates" === pathname
                              ? "active-master-class-icon menu-item-icon-more"
                              : "menu-item-icon-more"
                          }
                        />
                      ) : (
                        <ExpandMore
                          className={
                            "/opd-clinical" === pathname ||
                            "/certificates" === pathname
                              ? "active-master-class-icon menu-item-icon-more"
                              : "menu-item-icon-more"
                          }
                        />
                      )}
                    </ListItemButton>
                    <Collapse in={openMenu.opd} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding className="">
                        <ListItemButton
                          className={
                            "/opd-clinical" === pathname
                              ? "active-master-class sub-menu"
                              : "sub-menu"
                          }
                          sx={{
                            pl: 4,
                            borderRadius: "12px",
                            "&:hover": {
                              backgroundColor: "#e9eefa",
                              color: "#3b75ff",
                            },
                          }}
                          onClick={toggleDrawer("right", false)}
                        >
                          <ListItemText primary="OPD Clinical" />
                        </ListItemButton>
                        {/* <ListItemButton
                       className={
                         "/certificates" === pathname
                           ? "active-master-class sub-menu"
                           : "sub-menu"
                       }
                       sx={{
                         pl: 4,
                         borderRadius: "12px",
                         "&:hover": {
                           backgroundColor: "#e9eefa",
                           color: "#3b75ff",

                         },
                       }}
                       onClick={toggleDrawer("right", false)}
                     >
                       <ListItemText primary="Certificates" />
                     </ListItemButton> */}
                      </List>
                    </Collapse>
                  </ListItem>
                )}

                <ListItem
                  key={"Patient"}
                  disablePadding
                  className="nav-ListItemButton"
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    onClick={
                      openMenu.new ? handleClickPatient : handleClickMasterNull
                    }
                    sx={{
                      minHeight: 24,
                      justifyContent: openMenu.new ? "initial" : "center",
                      px: 0.5,
                      borderRadius: "12px",
                      "&:hover": {
                        backgroundColor: "#e9eefa",
                        color: "#3b75ff",
                      },
                    }}
                  >
                    <ListItemIcon
                      className="nav-ListItemButton"
                      onClick={handleClickPatient}
                      sx={{
                        minWidth: 0,
                        mr: openMenu.new ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {openMenu.new ? (
                        <span className="menu-icon-span">
                          <AssignmentIndOutlinedIcon
                            className={
                              "/patient-master" === pathname ||
                              "/patient-history" === pathname
                                ? "active-master-class-icon menu-icon"
                                : "menu-icon"
                            }
                          />
                        </span>
                      ) : (
                        <BootstrapTooltip
                          TransitionComponent={Zoom}
                          title={
                            <React.Fragment>
                              <span style={{ fontSize: "12px" }}>Patient</span>
                            </React.Fragment>
                          }
                          placement="right"
                        >
                          <span className="menu-icon-span">
                            <AssignmentIndOutlinedIcon
                              className={
                                "/patient-master" === pathname ||
                                "/patient-history" === pathname
                                  ? "active-master-class-icon menu-icon"
                                  : "menu-icon"
                              }
                            />
                          </span>
                        </BootstrapTooltip>
                      )}
                    </ListItemIcon>

                    <ListItemText
                      primary="Patient"
                      sx={{ opacity: openMenu.new ? 1 : 0 }}
                      className={
                        "/patient-master" === pathname ||
                        "/patient-history" === pathname
                          ? "active-master-class-icon menu-item-text"
                          : "menu-item-text"
                      }
                    />
                    {openMenu.patient ? (
                      <ExpandLess
                        sx={{
                          borderRadius: "12px",
                          "&:hover": {
                            backgroundColor: "#e9eefa",
                            color: "#3b75ff",
                          },
                        }}
                        className={
                          "/patient-master" === pathname ||
                          "/patient-history" === pathname
                            ? "active-master-class-icon menu-item-icon-more"
                            : "menu-item-icon-more"
                        }
                      />
                    ) : (
                      <ExpandMore
                        className={
                          "/patient-master" === pathname ||
                          "/patient-history" === pathname
                            ? "active-master-class-icon menu-item-icon-more"
                            : "menu-item-icon-more"
                        }
                      />
                    )}
                  </ListItemButton>
                  <Collapse in={openMenu.patient} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding className="">
                      <ListItemButton
                        className={
                          "/patient-master" === pathname
                            ? "active-master-class sub-menu"
                            : "sub-menu"
                        }
                        sx={{
                          pl: 4,
                          borderRadius: "12px",
                          "&:hover": {
                            backgroundColor: "#e9eefa",
                            color: "#3b75ff",
                          },
                        }}
                        onClick={toggleDrawer("right", false)}
                      >
                        <ListItemText primary="Patients" />
                      </ListItemButton>
                      {/* <ListItemButton
                        className={
                          "/patient-history" === pathname
                            ? "active-master-class sub-menu"
                            : "sub-menu"
                        }
                        sx={{
                          pl: 4,
                          borderRadius: "12px",
                          "&:hover": {
                            backgroundColor: "#e9eefa",
                            color: "#3b75ff",

                          },
                        }}
                        onClick={toggleDrawer("right", false)}
                      >
                        <ListItemText primary="Patient History" />
                      </ListItemButton> */}
                    </List>
                  </Collapse>
                </ListItem>
                {userContext.loginDetail.userRole !== "user" && (
                  <ListItem
                    key={"Team"}
                    disablePadding
                    className="nav-ListItemButton"
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      onClick={
                        openMenu.new ? handleClickTeam : handleClickMasterNull
                      }
                      sx={{
                        minHeight: 24,
                        justifyContent: openMenu.new ? "initial" : "center",
                        px: 0.5,
                        borderRadius: "12px",
                        "&:hover": {
                          backgroundColor: "#e9eefa",
                          color: "#3b75ff",
                        },
                      }}
                    >
                      <ListItemIcon
                        className="nav-ListItemButton"
                        onClick={handleClickTeam}
                        sx={{
                          minWidth: 0,
                          mr: openMenu.new ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {openMenu.new ? (
                          <span className="menu-icon-span">
                            <GroupsIcon
                              className={
                                "/staff" === pathname || "/users" === pathname
                                  ? "active-master-class-icon menu-icon"
                                  : "menu-icon"
                              }
                            />
                          </span>
                        ) : (
                          <BootstrapTooltip
                            TransitionComponent={Zoom}
                            title={
                              <React.Fragment>
                                <span style={{ fontSize: "12px" }}>Team</span>
                              </React.Fragment>
                            }
                            placement="right"
                          >
                            <span className="menu-icon-span">
                              <GroupsIcon
                                className={
                                  "/staff" === pathname || "/users" === pathname
                                    ? "active-master-class-icon menu-icon"
                                    : "menu-icon"
                                }
                              />
                            </span>
                          </BootstrapTooltip>
                        )}
                      </ListItemIcon>

                      <ListItemText
                        primary="Team"
                        sx={{ opacity: openMenu.new ? 1 : 0 }}
                        className={
                          "/staff" === pathname || "/users" === pathname
                            ? "active-master-class-icon menu-item-text"
                            : "menu-item-text"
                        }
                      />
                      {openMenu.team ? (
                        <ExpandLess
                          sx={{
                            borderRadius: "12px",
                            "&:hover": {
                              backgroundColor: "#e9eefa",
                              color: "#3b75ff",
                            },
                          }}
                          className={
                            "/staff" === pathname || "/users" === pathname
                              ? "active-master-class-icon menu-item-icon-more"
                              : "menu-item-icon-more"
                          }
                        />
                      ) : (
                        <ExpandMore
                          className={
                            "/staff" === pathname || "/users" === pathname
                              ? "active-master-class-icon menu-item-icon-more"
                              : "menu-item-icon-more"
                          }
                        />
                      )}
                    </ListItemButton>
                    <Collapse in={openMenu.team} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding className="">
                        <ListItemButton
                          className={
                            "/staff" === pathname
                              ? "active-master-class sub-menu"
                              : "sub-menu"
                          }
                          sx={{
                            pl: 4,
                            borderRadius: "12px",
                            "&:hover": {
                              backgroundColor: "#e9eefa",
                              color: "#3b75ff",
                            },
                          }}
                          onClick={toggleDrawer("right", false)}
                        >
                          <ListItemText primary="Staff" />
                        </ListItemButton>
                        <ListItemButton
                          className={
                            "/users" === pathname
                              ? "active-master-class sub-menu"
                              : "sub-menu"
                          }
                          sx={{
                            pl: 4,
                            borderRadius: "12px",
                            "&:hover": {
                              backgroundColor: "#e9eefa",
                              color: "#3b75ff",
                            },
                          }}
                          onClick={toggleDrawer("right", false)}
                        >
                          <ListItemText primary="Users" />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </ListItem>
                )}

                {userContext.loginDetail.userRole === "superadmin" && (
                  <ListItem
                    key={"CRM"}
                    disablePadding
                    className="nav-ListItemButton"
                    sx={{ display: "block" }}
                  >
                    <ListItemButton
                      onClick={
                        openMenu.new ? handleClickCrm : handleClickMasterNull
                      }
                      sx={{
                        minHeight: 24,
                        justifyContent: openMenu.new ? "initial" : "center",
                        px: 0.5,
                        borderRadius: "12px",
                        "&:hover": {
                          backgroundColor: "#e9eefa",
                          color: "#3b75ff",
                        },
                      }}
                    >
                      <ListItemIcon
                        className="nav-ListItemButton"
                        onClick={handleClickCrm}
                        sx={{
                          minWidth: 0,
                          mr: openMenu.new ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        {openMenu.new ? (
                          <span className="menu-icon-span">
                            <LeaderboardRoundedIcon
                              className={
                                "/lead" === pathname ||
                                "/distribute" === pathname
                                  ? "active-master-class-icon menu-icon"
                                  : "menu-icon"
                              }
                            />
                          </span>
                        ) : (
                          <BootstrapTooltip
                            TransitionComponent={Zoom}
                            title={
                              <React.Fragment>
                                <span style={{ fontSize: "12px" }}>CRM</span>
                              </React.Fragment>
                            }
                            placement="right"
                          >
                            <span className="menu-icon-span">
                              <LeaderboardRoundedIcon
                                className={
                                  "/lead" === pathname ||
                                  "/distribute" === pathname
                                    ? "active-master-class-icon menu-icon"
                                    : "menu-icon"
                                }
                              />
                            </span>
                          </BootstrapTooltip>
                        )}
                      </ListItemIcon>

                      <ListItemText
                        primary="CRM"
                        sx={{ opacity: openMenu.new ? 1 : 0 }}
                        className={
                          "/lead" === pathname || "/distribute" === pathname
                            ? "active-master-class-icon menu-item-text"
                            : "menu-item-text"
                        }
                      />
                      {openMenu.crm ? (
                        <ExpandLess
                          sx={{
                            borderRadius: "12px",
                            "&:hover": {
                              backgroundColor: "#e9eefa",
                              color: "#3b75ff",
                            },
                          }}
                          className={
                            "/lead" === pathname || "/distribute" === pathname
                              ? "active-master-class-icon menu-item-icon-more"
                              : "menu-item-icon-more"
                          }
                        />
                      ) : (
                        <ExpandMore
                          className={
                            "/lead" === pathname || "/distribute" === pathname
                              ? "active-master-class-icon menu-item-icon-more"
                              : "menu-item-icon-more"
                          }
                        />
                      )}
                    </ListItemButton>
                    <Collapse in={openMenu.crm} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding className="">
                        <ListItemButton
                          className={
                            "/lead" === pathname
                              ? "active-master-class sub-menu"
                              : "sub-menu"
                          }
                          sx={{
                            pl: 4,
                            borderRadius: "12px",
                            "&:hover": {
                              backgroundColor: "#e9eefa",
                              color: "#3b75ff",
                            },
                          }}
                          onClick={toggleDrawer("right", false)}
                        >
                          <ListItemText primary="Lead" />
                        </ListItemButton>
                        <ListItemButton
                          className={
                            "/distribute" === pathname
                              ? "active-master-class sub-menu"
                              : "sub-menu"
                          }
                          sx={{
                            pl: 4,
                            borderRadius: "12px",
                            "&:hover": {
                              backgroundColor: "#e9eefa",
                              color: "#3b75ff",
                            },
                          }}
                          onClick={toggleDrawer("right", false)}
                        >
                          <ListItemText primary="Distribute" />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  </ListItem>
                )}
                <ListItem
                  key={"Accounts"}
                  disablePadding
                  className="nav-ListItemButton"
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    onClick={
                      openMenu.new ? handleClickAccounts : handleClickMasterNull
                    }
                    sx={{
                      minHeight: 24,
                      justifyContent: openMenu.new ? "initial" : "center",
                      px: 0.5,
                      borderRadius: "12px",
                      "&:hover": {
                        backgroundColor: "#e9eefa",
                        color: "#3b75ff",
                      },
                    }}
                  >
                    <ListItemIcon
                      className="nav-ListItemButton"
                      sx={{
                        minWidth: 0,
                        mr: openMenu.new ? 3 : "auto",
                        justifyContent: "center",
                      }}
                      onClick={handleClickAccounts}
                    >
                      {openMenu.new ? (
                        <span className="menu-icon-span">
                          <AccountBalanceIcon
                            className={
                              "/billing" === pathname
                                ? "active-master-class-icon menu-icon"
                                : "menu-icon"
                            }
                          />
                        </span>
                      ) : (
                        <BootstrapTooltip
                          TransitionComponent={Zoom}
                          title={
                            <React.Fragment>
                              <span style={{ fontSize: "12px" }}>Accounts</span>
                            </React.Fragment>
                          }
                          placement="right"
                        >
                          <span className="menu-icon-span">
                            <AccountBalanceIcon
                              className={
                                "/billing" === pathname
                                  ? "active-master-class-icon menu-icon"
                                  : "menu-icon"
                              }
                            />
                          </span>
                        </BootstrapTooltip>
                      )}
                    </ListItemIcon>

                    <ListItemText
                      primary="Accounts"
                      sx={{ opacity: openMenu.new ? 1 : 0 }}
                      className={
                        "/billing" === pathname
                          ? "active-master-class-icon menu-item-text"
                          : "menu-item-text"
                      }
                    />
                    {openMenu.accounts ? (
                      <ExpandLess
                        sx={{
                          borderRadius: "12px",
                          "&:hover": {
                            backgroundColor: "#e9eefa",
                            color: "#3b75ff",
                          },
                        }}
                        className={
                          "/billing" === pathname
                            ? "active-master-class-icon menu-item-icon-more"
                            : "menu-item-icon-more"
                        }
                      />
                    ) : (
                      <ExpandMore
                        className={
                          "/billing" === pathname
                            ? "active-master-class-icon menu-item-icon-more"
                            : "menu-item-icon-more"
                        }
                      />
                    )}
                  </ListItemButton>
                  <Collapse in={openMenu.accounts} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding className="">
                      <ListItemButton
                        className={
                          "/billing" === pathname
                            ? "active-master-class sub-menu"
                            : "sub-menu"
                        }
                        sx={{
                          pl: 4,
                          borderRadius: "12px",
                          "&:hover": {
                            backgroundColor: "#e9eefa",
                            color: "#3b75ff",
                          },
                        }}
                        onClick={toggleDrawer("right", false)}
                      >
                        <ListItemText primary="Billing" />
                      </ListItemButton>
                    </List>
                  </Collapse>
                </ListItem>
                {userContext.loginDetail.userRole !== "user" && (
                  <ListItem
                    key={"Master"}
                    disablePadding
                    className={
                      "/master" === pathname
                        ? "active-master-class"
                        : "nav-ListItemButton"
                    }
                    sx={{ display: "block" }}
                    onClick={handleClickMaster}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 24,
                        justifyContent: openMenu.new ? "initial" : "center",
                        px: 0.5,
                        borderRadius: "12px",
                        "&:hover": {
                          backgroundColor: "#e9eefa",
                          color: "#3b75ff",
                        },
                      }}
                    >
                      <ListItemIcon
                        className="nav-ListItemButton"
                        sx={{
                          minWidth: 0,
                          mr: openMenu.new ? 3 : "auto",
                          justifyContent: "center",
                        }}
                        onClick={handleClickMaster}
                      >
                        {openMenu.new ? (
                          <span className="menu-icon-span">
                            <AutoAwesomeMosaicOutlinedIcon className="menu-icon" />
                          </span>
                        ) : (
                          <BootstrapTooltip
                            TransitionComponent={Zoom}
                            title={
                              <React.Fragment>
                                <span style={{ fontSize: "12px" }}>Master</span>
                              </React.Fragment>
                            }
                            placement="right"
                          >
                            <span className="menu-icon-span">
                              <AutoAwesomeMosaicOutlinedIcon className="menu-icon" />
                            </span>
                          </BootstrapTooltip>
                        )}
                      </ListItemIcon>
                      <ListItemText
                        primary={"Master"}
                        onClick={handleClickMaster}
                        sx={{ opacity: openMenu.new ? 1 : 0 }}
                        className="menu-item-text"
                      />
                    </ListItemButton>
                  </ListItem>
                )}
              </span>
            </List>
          </Drawer>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              backgroundColor: "#f1f5f8",
              width: "100%",
            }}
          >
            <DrawerHeader id="drwerheader" />
            <Box sx={{ padding: "16px" }}>{props.children}</Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginX: "24px !important",
                marginBottom: "20px !important",
                flexDirection: smallScreen ? "column" : "row",
                overflow: "hidden",
              }}
            >
              <Typography className="font-size-11">
                Copyrights &copy; {new Date().getFullYear()} - Pediaverse
                Solutions Pvt Ltd
              </Typography>
              <Typography className="font-size-11">
                <a
                  href="/terms"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  className="blue-link"
                >
                  Terms of Service
                </a>{" "}
                &nbsp;{" "}
                <a
                  href="/privacy-policy"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  className="blue-link"
                >
                  Privacy Policy
                </a>
              </Typography>
            </Box>
          </Box>
        </Box>
      )}

      {/* profile popup */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box component="span">
          {userContext.loginDetail.loginClick === true || orgColl.length < 2 ? (
            <Grid className="main-profie-container">
              <>
                <Grid className="profile-user-detail-component">
                  <Grid className="profile-component-grid">
                    <Grid width={"100%"} paddingX={"8px"}>
                      <Grid className="organization-container">
                        <Grid
                          color={"#0b0b45 !important"}
                          sx={{ paddingTop: "2px" }}
                        >
                          <BusinessIcon />
                        </Grid>
                        <Grid marginTop={0.5} marginLeft={1.3}>
                          <h5 className="profilename">
                            {userContext.organizationName} {" ("}
                            {userContext.branchName}
                            {")"}
                          </h5>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className="avatar-upload">
                      <Grid className="avatar-edit"></Grid>
                      <Grid className="avatar-preview">
                        <Typography
                          className="avtar"
                          {...stringAvatar(
                            userContext.loginDetail.firstName +
                              " " +
                              userContext.loginDetail.lastName
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid className="profile-name-grid">
                    <h4 className="text-size-medium">
                      {userContext.loginDetail.fullName}
                    </h4>
                    <Grid style={{ marginTop: "-5px" }}>
                      <Typography className="text-size-small">
                        {userContext.loginDetail.email}
                      </Typography>
                    </Grid>
                    <Grid className="grid-myprofile">
                      {/* <Button
                        className="one-myprofile"
                        color="primary"
                        variant="outlined"
                        size="medium"
                        onClick={() => profileClose("My Profile")}
                      >
                        My Profile
                      </Button> */}
                    </Grid>
                  </Grid>
                </Grid>
                {branchTotalCount > 1 &&
                branchColl.length > 1 &&
                userContext.loginDetail.userRole !== "user" ? (
                  //&& userContext.subscriptionId > 0
                  <Grid className="branch-selection-component">
                    <PopupState variant="popover" popupId="demo-popup-popover">
                      {(popupState) => (
                        <div>
                          <BootstrapTooltip title={userContext.branchName}>
                            <Button
                              className="branch-selection-btn"
                              {...bindTrigger(popupState)}
                              variant="outlined"
                              startIcon={<BusinessIcon />}
                            >
                              <Typography className="branch-selection">
                                {userContext.branchName}
                              </Typography>
                            </Button>
                          </BootstrapTooltip>
                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            className="nav-popover-branch"
                          >
                            <Box
                              className="branch-container"
                              sx={{
                                "& > :not(style)": {
                                  maxwidth: "20px",
                                },
                              }}
                            >
                              <List>
                                {branchColl.map((item: any, index) => {
                                  return (
                                    <ListItem
                                      disablePadding
                                      button
                                      onClick={() => {
                                        popupState.close();
                                        handleListItemBranchClick(item);
                                      }}
                                      key={index}
                                    >
                                      <ListItemText primary={item.branchName} />
                                    </ListItem>
                                  );
                                })}
                              </List>
                            </Box>
                          </Popover>
                        </div>
                      )}
                    </PopupState>
                  </Grid>
                ) : (
                  <></>
                )}
                {userContext.loginDetail.userRole === "superadmin" ||
                userContext.loginDetail.userRole === "clientadmin" ? (
                  <Grid className="organization-component">
                    <List sx={{ padding: "0px !important" }}>
                      {orgColl.map((item: any, index) => {
                        return (
                          <Grid
                            item
                            className="organization-list-data"
                            key={index}
                          >
                            {userContext.organizationId ===
                            item.organizationId ? (
                              <></>
                            ) : (
                              <ListItem
                                disablePadding
                                button
                                onClick={() =>
                                  handleListItemOrganizationClick(item)
                                }
                                key={index}
                              >
                                <span className="organization-list-icon">
                                  <Avatar
                                    {...stringAvatarOrganization(
                                      item.organizationName
                                    )}
                                    style={{ paddingTop: "4px" }}
                                  />
                                </span>
                                <ListItemText
                                  primary={
                                    <h4 style={{ fontSize: "14px" }}>
                                      {item.organizationName}
                                    </h4>
                                  }
                                />
                              </ListItem>
                            )}
                          </Grid>
                        );
                      })}
                    </List>
                  </Grid>
                ) : null}
                <Grid className="sign-out-grid">
                  <Grid>
                    <Button
                      className="sign-out-btn-myprofile"
                      color="primary"
                      variant="contained"
                      size="medium"
                      onClick={() => profileClose("Sign out")}
                      startIcon={<LogoutIcon />}
                    >
                      Sign out
                    </Button>
                  </Grid>
                </Grid>
              </>
            </Grid>
          ) : (
            <></>
          )}
        </Box>
      </Popover>
      {/* profile popup */}
    </Box>
  );
}
