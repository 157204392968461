import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { displayNameMapping } from "./BreadcrumbHelper";

const CustomBreadcrumbs = () => {
  const location = useLocation();
  const { pathname } = location;
  const segments = pathname.split("/").filter(Boolean);
  let url = "";
  const breadcrumbLink = segments.map((segment, index: number) => {
    url += `/${segment}`;
    // const displayName =  segment?.replace(/-/g,' ');
    const displayName = segment
      ?.split("-")
      ?.map((x) => {
        if (displayNameMapping[x]) {
          return displayNameMapping[x];
        } else {
          return x;
        }
      })
      ?.join(" ");
    const isLast = segments.length - 1;
    return (
      <Link
        key={index}
        to={url}
        style={{
          fontSize: "14px",
          textDecoration: "none",
          fontWeight: "500",
          color: index !== isLast ? "#3b75ff" : "black",
        }}
      >
        <span style={{ textTransform: "capitalize" }}>
          {displayName} {index !== isLast ? "/ " : ""}
        </span>
      </Link>
    );
  });

  return <div>{breadcrumbLink}</div>;
};

export default CustomBreadcrumbs;
